import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from "react-helmet";

let cmain = '#C0573B'
let black = 'rgb(38,38,38)'

let id_temp = 'mamaw-rizal'
let id = "mamaw-rizal"
let inisial_co = 'Rizal'
let inisial_ce = 'Mamaw'

let lengkap_co = (<>Rizal Fauzi Akbar S.Sos </>)
let lengkap_ce = (<>Maulidiani S.Sos</>)

let bapak_co = 'Bpk Tatang Sobari'
let ibu_co = 'Ibu Hani Herawati'
let bapak_ce = "Bpk Yusep Wilman Doni, S.E"
let ibu_ce = "Ibu Rasti"

let ig_co = "rizalfakbare"
let ig_ce = "maulidianiiii"

let foto_ce = pw(id_temp, "ce.jpg")
let foto_co = pw(id_temp, "co.jpg")
let waktunikah = "01/03/2021"

let modal = pw(id_temp, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://goo.gl/maps/eLZfCJdjffZe4Tyn8"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=NmF1aWxxYm0wMzQwdDUyanI3M2tydWxuMHEgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.84339058117!2d107.64733481477278!3d-6.909322195007634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e7e9686511f3%3A0x114a0aba31a88c95!2sGedung%20Sopo%20Sulaksana!5e0!3m2!1sid!2sid!4v1608140145034!5m2!1sid!2sid"

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "${this.state.hadir} ${this.state.hadir ? 'sesi ' + this.state.sesi : ""}", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
        let slider = []
        for (let index = 1; index < 11; index++) {
            slider[index] = gambar(pw(id_temp, index + ".jpg"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720')
        }
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helmet>
                    <link rel="icon"
                        type="image/ico"
                        href={logo} />
                    <meta charset="UTF-8" />
                    <title>{`Undanganku - ${inisial_ce} & ${inisial_co}`}</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
                    <meta name="description" content={`Spesial mengundang untuk menghadiri pernikahan kami. ${inisial_ce} & ${inisial_co}`} />
                    <meta name="keywords" content="undangan digital, undangan online, undangan nikah, website undangan, pernikahan, wedding, marriage" />
                    <meta name="author" content="nikahankami (dodolantech@gmail.com)" />
                    <meta name="robots" content="follow, index" />
                    <meta property="og:image" content={pw(id,"thumb.jpg")} />
                    <meta property="og:image" itemprop="image" content={pw(id,"thumb.jpg")} />
                    <link itemprop="thumbnailUrl" href={pw(id,"thumb.jpg")} /> <span itemprop="thumbnail" itemscope itemtype="http://schema.org/ImageObject"> <link itemprop="url" href={pw(id,"thumb.jpg")} /> </span>
                    <meta property="og:image" content={pw(id,"thumb.jpg")} />
                    <meta property="og:title" content={`Undanganku - ${inisial_ce} & ${inisial_co}`} />
                    <meta property="og:description" content={`Spesial mengundang untuk menghadiri pernikahan kami. ${inisial_ce} & ${inisial_co}`} />
                    <meta property="og:updated_time" content="1440432930" />
                    <meta http-equiv="cache-control" content="max-age=0" />
                    <meta http-equiv="cache-control" content="no-cache" />
                    <meta http-equiv="expires" content="0" />
                    <meta http-equiv="expires" content="Tue, 01 Jan 1980 1:00:00 GMT" />
                    <meta http-equiv="pragma" content="no-cache" />
                </Helmet>
                <div id='gold5' style={{
                    backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={gambar(openlogo)} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>

                                        <p className="fs16 text-center cblack px-3">
                                            And one of His signs is that He created for you spouses from among yourselves so that you may find comfort in them. And He has placed between you compassion and mercy. Surely in this are signs for people who reflect.<br /><br />(Ar- Rum 21)

                                        </p>
                                    </Item>
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>

                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            The Intimate Wedding of <br /> {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2' style={{
                                                backgroundImage: `url('https://img.freepik.com/free-vector/white-elegant-texture-background_23-2148445838.jpg?size=626&ext=jpg')`,
                                                bakcgroundSize: '100%'
                                            }}>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{ fontSize: '32px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center'
                                                        style={{ fontSize: '24px', color: 'black' }}>
                                                        <b>Putri dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2' style={{
                                                backgroundImage: `url('https://img.freepik.com/free-vector/white-elegant-texture-background_23-2148445838.jpg?size=626&ext=jpg')`,
                                                bakcgroundSize: '100%'
                                            }}>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '32px',
                                                        fontFamily: "'Marck Script', cursive", color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '24px', color: 'black' }}>
                                                        <b>Putra dari:</b><br />
                                                        {bapak_co}
                                                        <br />
                                                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: black }} >
                                    <Item>
                                        <p className="fs16">
                                            Yang Insyaa Allah akan dilaksanakan pada:
                    </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20">
                                            <b>
                                                MINGGU <span className="fs36">03</span> JANUARI 2021
                      </b>
                                        </p>
                                    </Item>

                                    <Item>

                                        <p className="fs20 col-sm-4" style={{ color: cmain }}>
                                            <Item>
                                                <div className="rounded-circle" style={{ backgroundColor: '#FE646F', padding: '10px', maxHeight: '55px', maxWidth: '55px' }}>
                                                    <img src={pw("asset", "heart.svg")} style={{ height: '35px', witdh: '35px' }} />
                                                </div>
                                            </Item>
                                            <b>Akad Nikah </b><br />
                                            <span className="cblack fs16">

                                                08.00 WIB - Selesai<br />
                                                <div style={{ fontStyle: 'italic' }}>
                                                    (Family Only)
                                            </div>
                                            </span>
                                            {/* <br />
                      <span className="cblack fs12">
                        (Hanya dihadiri oleh keluarga)
                      </span> */}

                                        </p>
                                        {/* <p className="px-3 d-none d-md-block" style={{ color: cmain, fontSize: '72px' }}>
                                            \
                                        </p> */}
                                        <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: cmain }}>
                                            <Item>
                                                <div className="rounded-circle" style={{ backgroundColor: '#FE646F', padding: '10px', maxHeight: '55px', maxWidth: '55px' }}>
                                                    <img src={pw("asset", "heart.svg")} style={{ height: '35px', witdh: '35px' }} />
                                                </div>
                                            </Item>
                                            <b>Resepsi</b><br />

                                            <span className="cblack fs16">

                                                Sesi 1 : 11.00-12.00 <br />
                                                    Sesi 2 : 12.00 -13.00 <br />
                                                    Sesi 3 : 13.00-14.00
                                               </span>


                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs16 pt-3">
                                            <b>Gedung Sopo Sulaksana
                                            <br />
                                            Jl. Sekolah Internasional, Cicaheum, Kec. Kiaracondong, Kota Bandung, Jawa Barat 40282
                                            </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p style={{ fontSize: '16px', fontStyle: 'italic' }}>Undangan Hanya untuk 2 orang</p>
                                    </Item>
                                    <Item>
                                        <div className="px-2">
                                            <div style={{ border: `2px solid ${cmain}` }} className="mb-3 mx-2">
                                                <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                                    <iframe width="375" height="275" id="gmap_canvas"
                                                        src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                                    </iframe></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />

                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={covid} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14'>
                                                            “Love recognizes no barriers. It jumps hurdles, leaps fences, penetrates walls to arrive at its destination full of hope.”
<br />– Maya Angelou
</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>
                                <Container id='sectiongold58' >
                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Hadir</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Tidak Hadir</label>
                                                                    </div>
                                                                    {!hadir ? false : (
                                                                        <>  <Alert variant='dark col-12 mr-4 '>
                                                                            <p className="f-14">
                                                                                Silahkan Pilih Jadwal Kedatangan
                                      </p>
                                                                            <div onClick={() => {
                                                                                this.setState({ sesi: 1 })
                                                                            }
                                                                            }
                                                                                className="pl-5">
                                                                                <input type="radio" id="featured-3" name="sesi" checked={sesi == 1 ? true : false} />
                                                                                <label for="featured-3">

                                                                                    Sesi 1 : 11.00-12.00 Wib</label>
                                                                            </div>
                                                                            <div onClick={() => {
                                                                                this.setState({ sesi: 2 })
                                                                            }
                                                                            } className="pl-5">
                                                                                <input type="radio" id="featured-4" name="sesi" checked={sesi == 2 ? true : false} />
                                                                                <label for="featured-4"
                                                                                >Sesi 2 : 12.00 -13.00  Wib</label>
                                                                            </div>
                                                                            <div onClick={() => {
                                                                                this.setState({ sesi: 3 })
                                                                            }
                                                                            } className="pl-5">
                                                                                <input type="radio" id="featured-4" name="sesi" checked={sesi == 3 ? true : false} />
                                                                                <label for="featured-5"

                                                                                >Sesi 3 : 13.00-14.00 Wib</label>
                                                                            </div>
                                                                            <p className="f-14">
                                                                                Tamu undangan diharapkan hadir sesuai dengan sesi yang telah ditentukan
                                                                         </p>
                                                                        </Alert>
                                                                        </>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Alert>
                                                                        ) : false)
                                                                }
                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>
            </>


        )
    }
}

